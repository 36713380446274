import React from 'react'
import Helmet from 'react-helmet'
import Section from '../../../components/Section'
import Typography from '../../../components/Typography'
import styled from '@emotion/styled'
import Layout from '../../../layouts'

const Iframe = styled.iframe`
  height: 4250px;
`

const JobsPage = () => (
  <Layout>
    <Helmet htmlAttributes={{ lang: 'en-US' }}>
      <title>Available Jobs | Just Energy</title>
      <meta
        name="description"
        content="Click here to see available jobs at all Just Energy locations."
      />
    </Helmet>
    <Section>
      <Typography variant="h1" rendersAs="h1">
        Available Jobs
      </Typography>
      <Iframe
        id="b9caa187-74db-4357-8586-17dbea2dcd57"
        src="https://justenergy.taleo.net/careersection/ex/jobsearch.ftl?lang=en&amp;portal=101430233"
        kwframeid="1"
        width="960px"
        height="1000px"
        frameborder="0"
      />
    </Section>
  </Layout>
)

export default JobsPage
